import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingSurveys } from '../../custom-ducks/activities.duck';
import { useSurvey } from '../../util/hooks/useSurvey';
import axios from 'axios';
import config from '../../config';
import { getBearerToken } from '../../util/cookie';

/**
 * SurveyManager component that fetches pending surveys on mount
 * and displays them to authenticated users.
 * This component should be mounted at the app level so it's accessible from all pages.
 */
const SurveyManager = () => {
  const dispatch = useDispatch();
  const { openSurveyPopup } = useSurvey();
  const [hasCheckedSurveys, setHasCheckedSurveys] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Get surveys from Redux store
  const { surveys, surveysFetching, surveysError } = useSelector(state => state.activities);

  // Get current user info
  const { currentUser } = useSelector(state => state.user);

  // Check if user is authenticated
  useEffect(() => {
    if (currentUser && currentUser.id) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [currentUser]);

  // Fetch surveys on component mount if user is authenticated
  useEffect(() => {
    const fetchSurveys = async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        await dispatch(getPendingSurveys());
        setHasCheckedSurveys(true);
      } catch (error) {
        console.error('Error fetching surveys:', error);
        setHasCheckedSurveys(true);
      }
    };

    fetchSurveys();
  }, [dispatch, isAuthenticated]);

  // Show the first pending survey when surveys are loaded
  useEffect(() => {
    if (hasCheckedSurveys && !surveysFetching && surveys.length > 0 && isAuthenticated) {
      const pendingSurvey = surveys.find(survey => survey.isActive);

      if (pendingSurvey) {
        setTimeout(() => {
          // Open the survey popup with the survey ID
          openSurveyPopup(pendingSurvey.surveyId);
        }, 5000);
      }
    }
  }, [surveys, surveysFetching, hasCheckedSurveys, openSurveyPopup, isAuthenticated]);

  // This component doesn't render anything visible
  return null;
};

export default SurveyManager;
