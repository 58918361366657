import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import routeConfiguration from '../../routeConfiguration';
// import { createResourceLocatorString } from '../../util/routes';
import { isScrollingDisabled } from '../../ducks/UI.duck';
// import {LayoutWrapperFooter} from '../../components';
import Footer from '../../custom-components/elements/footer/Footer';
import Icon_404 from '../../assets/404.png'

import css from './NotFoundPage.css';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';
// import StaticpagesLayoutWrapper
//   from '../../custom-components/wrappers/staticpages-layout-wrapper/StaticpagesLayoutWrapper';

export class NotFoundPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;
  }

  render() {
    // const { history } = this.props;

    // const title = intl.formatMessage({
    //   id: 'NotFoundPage.title',
    // });

    // const handleSearchSubmit = values => {
    //   const { search, selectedPlace } = values.location;
    //   const { origin, bounds } = selectedPlace;
    //   const searchParams = { address: search, origin, bounds };
    //   history.push(
    //     createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
    //   );
    // };

    return (
      <PageWrapper>
        <MainDashboardWrapper>
          {/* content */}
          <ContainerWrapper>
            <div className={css.errorContainer}>
              <div className={css.oops}>Oops!</div>
              <div className={css.notfound_2}>What are you <span>doing here</span></div>
              <div className={css.notfound_3}>I don't know how you got here</div>

              <img alt="not found" className={css.notfoundimage} src={Icon_404}/>
            </div>
          </ContainerWrapper>

          <ContainerWrapper>
            <Footer />
          </ContainerWrapper>

          {/*<LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <div className={css.content}>
                <div className={css.number}>404</div>
                <h1 className={css.heading}>
                  <FormattedMessage id="NotFoundPage.heading" />
                </h1>
                <p className={css.description}>
                  <FormattedMessage id="NotFoundPage.description" />
                </p>
                <LocationSearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>*/}
        </MainDashboardWrapper>
      </PageWrapper>
    );
  }
}

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

const { bool, func, object, shape } = PropTypes;

NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(NotFoundPageComponent);

export default NotFoundPage;
