import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
export const notifySuccess = (message: string) => toast.success(message);
export const notifyError = (message: string) => toast.error(message);

const ToastProvider = () => {

  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}/>
  );
};

export default ToastProvider;
