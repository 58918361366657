/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import css from './Services.module.css';
import { services } from '../../../../marketplace-custom-config';
import { Multiselect } from 'multiselect-react-dropdown';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import DashboardAddButton from '../../../elements/dashboard-add-button/DashboardAddButton';
import { notifyError, notifySuccess } from '../../../../components/HotToast/HotToastProvider';

function Services(props) {
  const { onUpdateListing, listing, setTabIndex } = props;
  const [inputs, setInputs] = useState({
    price: '0',
    per: 'hourly',
    typeOfService: [],
    meetingType: [],
    service: [],
  });
  const [errors, setErrors] = useState([]);

  const dropdownStyle = {
    chips: { background: '#007373', borderRadius: '8px', minHeight: '40px' },
    searchBox: {
      border: '1px solid rgba(144, 144, 144, 0.705)',
      borderRadius: '9px',
      minHeight: '50px',
    },
    option: { backgroundColor: 'white', color: '#666666' },
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      price:
        listing.attributes.price && listing.attributes.price.amount
          ? listing.attributes.price.amount / 100
          : '0',
      per: listing.attributes.publicData.per || 'hourly',
      typeOfService: listing.attributes.publicData['type-of-service'] || [],
      meetingType: listing.attributes.publicData['meeting-type'] || [],
      service: listing.attributes.publicData['service-delivery-to'] || [],
    });
  }, []);

  const validateFields = () => {
    let validationErrors = [];
    let isValid = true;

    const regex = RegExp(/^[1-9]\d{0,4}(?:\.\d{1,4})?$/g);
    const { price, typeOfService, meetingType, service } = inputs;
    if (!regex.test(price)) {
      validationErrors.push('Price is invalid.');
      setErrors(validationErrors);
      isValid = false;
    }

    if (typeOfService.length < 1) {
      validationErrors.push('Add the type of service.');
      setErrors(validationErrors);
      isValid = false;
    }

    if (meetingType.length < 1) {
      validationErrors.push('Select meeting type');
      setErrors(validationErrors);
      isValid = false;
    }

    if (service.length < 1) {
      validationErrors.push('Select who you deliver the service to');
      setErrors(validationErrors);
      isValid = false;
    }

    return isValid;
  };

  const onNext = () => {
    let tempObj = { publicData: {} };
    if (inputs.price) {
      let price = {};
      price.amount = parseInt(inputs.price) * 100;
      price.currency = 'USD';
      price._sdkType = 'money';
      tempObj.price = price;
    }

    if (inputs.typeOfService) {
      tempObj.publicData['type-of-service'] = inputs.typeOfService;
    }

    if (inputs.per) {
      tempObj.publicData.per = inputs.per;
    }

    if (inputs.meetingType) {
      tempObj.publicData['meeting-type'] = inputs.meetingType;
    }

    if (inputs.service) {
      tempObj.publicData['service-delivery-to'] = inputs.service;
    }

    if (validateFields()) {
      onUpdateListing('service', { ...tempObj, id: listing.id }).then(res => {
        setErrors([]);
        props.history.push(`/l/edit/${res.data.data.id.uuid}/edit/gallery`);
        notifySuccess('Saved');
        setTabIndex(2);
      });
    } else {
      notifyError('Has non valid inputs');
    }
  };

  const onSwitchClick = (state, key) => {
    if (inputs[state]) {
      if (inputs[state].includes(key)) {
        setInputs({
          ...inputs,
          [state]: inputs[state].filter(x => x !== key),
        });
      } else {
        let tempArray = [...inputs[state]];
        tempArray.push(key);
        setInputs({ ...inputs, [state]: tempArray });
      }
    }
  };

  const multiselectToState = e => {
    let tempArray = [];
    for (let index = 0; index < e.length; index++) {
      tempArray.push(e[index].key);
    }
    return tempArray;
  };

  const getMultiSelectedValues = name => {
    let tempArray = [];
    if (listing.attributes.publicData[name]) {
      for (let i = 0; i < listing.attributes.publicData[name].length; i++) {
        tempArray[i] = {
          key: listing.attributes.publicData[name][i],
          label: listing.attributes.publicData[name][i],
        };
      }
    }
    return tempArray;
  };

  return (
    <div className={css.wrapper}>
      <div className={css.price}>
        <p>Price</p>
        <div>
          <input
            type="number"
            value={inputs.price}
            onChange={e => setInputs({ ...inputs, price: e.target.value })}
          />
          <select value={inputs.per} onChange={e => setInputs({ ...inputs, per: e.target.value })}>
            <option value="hourly">Per Hour</option>
            <option value="package">Package</option>
          </select>
        </div>
        <p>Once someone makes an order, you will receive this amount minus</p>
        <p className={css.link}>
          Viveka fee and a payment process fee (${((inputs.price / 100) * 10).toFixed(2)})
        </p>
      </div>
      <div className={css.typeOfSearvice}>
        <p>Type of service</p>
        <Multiselect
          onRemove={e => setInputs({ ...inputs, typeOfService: multiselectToState(e) })}
          onSelect={e => setInputs({ ...inputs, typeOfService: multiselectToState(e) })}
          style={dropdownStyle}
          options={services}
          displayValue="label"
          selectedValues={getMultiSelectedValues('type-of-service')}
        />
      </div>

      <div className={css.meetingType}>
        <p>Meeting type</p>
        <div className={css.checkBox}>
          <div
            onClick={e => onSwitchClick('meetingType', 'Remote')}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.meetingType.includes('Remote') ? css.selectedCategory : ''
              }`}
            />
            <div>Remote</div>
          </div>

          {/*<div*/}
          {/*  className={inputs.meetingType.includes('Remote') ? css.selected : ''}*/}
          {/*  onClick={e => onSwitchClick('meetingType', 'Remote')}*/}
          {/*>*/}
          {/*  Remote*/}
          {/*</div>*/}

          <div
            onClick={e => onSwitchClick('meetingType', 'In Person')}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.meetingType.includes('In Person') ? css.selectedCategory : ''
              }`}
            />
            <div>In Person</div>
          </div>

          {/*<div*/}
          {/*  className={inputs.meetingType.includes('In Person') ? css.selected : ''}*/}
          {/*  onClick={e => onSwitchClick('meetingType', 'In Person')}*/}
          {/*>*/}
          {/*  In Person*/}
          {/*</div>*/}

          <div
            onClick={e => onSwitchClick('meetingType', 'Retreat')}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.meetingType.includes('Retreat') ? css.selectedCategory : ''
              }`}
            />
            <div>Retreat</div>
          </div>

          {/*<div*/}
          {/*  className={inputs.meetingType.includes('Retreat') ? css.selected : ''}*/}
          {/*  onClick={e => onSwitchClick('meetingType', 'Retreat')}*/}
          {/*>*/}
          {/*  Retreat*/}
          {/*</div>*/}
        </div>
      </div>

      <div className={css.serviceTo}>
        <p>Service delivery to</p>
        <div className={css.checkBox}>
          <div
            onClick={e => onSwitchClick('service', 'Individuals')}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.service.includes('Individuals') ? css.selectedCategory : ''
              }`}
            />
            <div>Individuals</div>
          </div>

          {/*<div*/}
          {/*  className={inputs.service.includes('Individuals') ? css.selected : ''}*/}
          {/*  onClick={e => onSwitchClick('service', 'Individuals')}*/}
          {/*>*/}
          {/*  Individuals*/}
          {/*</div>*/}

          <div
            onClick={e => onSwitchClick('service', 'Couple')}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.service.includes('Couple') ? css.selectedCategory : ''
              }`}
            />
            <div>Couple</div>
          </div>

          {/*<div*/}
          {/*  className={inputs.service.includes('Couple') ? css.selected : ''}*/}
          {/*  onClick={e => onSwitchClick('service', 'Couple')}*/}
          {/*>*/}
          {/*  Couple*/}
          {/*</div>*/}

          <div
            onClick={e => onSwitchClick('service', 'Small group')}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.service.includes('Small group') ? css.selectedCategory : ''
              }`}
            />
            <div>Small group</div>
          </div>

          {/*<div*/}
          {/*  className={inputs.service.includes('Small group') ? css.selected : ''}*/}
          {/*  onClick={e => onSwitchClick('service', 'Small group')}*/}
          {/*>*/}
          {/*  Small group*/}
          {/*</div>*/}
        </div>
      </div>
      <div className={css.errors}>
        {errors.map((item, index) => (
          <p key={index} className={css.error}>
            {item}
          </p>
        ))}
      </div>
      {/*<div className={css.next}>*/}
      {/*  <p>Save & Continue</p>*/}
      {/*  <DashboardAddButton onClick={() => onNext()} icon={faArrowRight} />*/}
      {/*</div>*/}

      <div onClick={() => onNext()} className={css.button}>
        Continue
      </div>
    </div>
  );
}

export default withRouter(Services);
