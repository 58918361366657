import React from "react";

const Breadcrumbs = ({ items = [] }) => {
  if (items.length === 0) return null;

  return (
    <nav aria-label="breadcrumb" className="flex p-2 rounded">
      <ol className="list-none p-0 m-0 flex items-center space-x-1">
        {items.map((item, index) => {
          const isLast = index === items.length - 1;

          return (
            <li key={index} className="flex items-center">
              {item.href && !isLast ? (
                <a
                  href={item.href}
                  className="text-gray-400 hover no-underline font-bold text-sm"
                >
                  {item.text}
                </a>
              ) : (
                <span
                  className={`text-sm font-bold ${isLast ? "text-[#007373]" : "text-gray-400"}`}
                  aria-current={isLast ? "page" : undefined}
                >
                  {item.text}
                </span>
              )}
              {!isLast && (
                <svg
                  className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
