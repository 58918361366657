import React from 'react';
import { Button } from '../../components';
import { useSurvey } from '../../util/hooks/useSurvey';

/**
 * Example component showing how to open the survey popup from anywhere in the app.
 */
const SurveyPopupExample = () => {
  const { openSurveyPopup } = useSurvey();

  const handleOpenSurvey = () => {
    // You can use any survey ID here
    // In a real implementation, this would be a real survey ID from your database
    openSurveyPopup('example-survey-1');
  };

  return (
    <div style={{ margin: '20px' }}>
      <h2>Survey Popup Example</h2>
      <p>Click the button below to open a survey popup:</p>
      <Button onClick={handleOpenSurvey}>Open Survey</Button>
    </div>
  );
};

export default SurveyPopupExample;
