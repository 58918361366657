// ================ Action types ================ //

import { getSurveyById } from '../containers/SaaSClientActiveSessionPage/helper';
import axios from 'axios';
import { getBearerToken } from '../util/cookie';
import config from '../config';

export const OPEN_SURVEY = 'app/Survey/OPEN_SURVEY';
export const CLOSE_SURVEY = 'app/Survey/CLOSE_SURVEY';
export const SET_SURVEY_DATA = 'app/Survey/SET_SURVEY_DATA';
export const SUBMIT_SURVEY = 'app/Survey/SUBMIT_SURVEY';

// ================ Reducer ================ //

const initialState = {
  isOpen: false,
  surveyId: null,
  surveyData: null,
  isSubmitting: false,
  submitError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_SURVEY:
      return {
        ...state,
        isOpen: true,
        surveyId: payload.surveyId,
      };
    case CLOSE_SURVEY:
      return {
        ...state,
        isOpen: false,
        surveyId: null,
        surveyData: null,
        isSubmitting: false,
        submitError: null,
      };
    case SET_SURVEY_DATA:
      return {
        ...state,
        surveyData: payload.surveyData,
      };
    case SUBMIT_SURVEY:
      return {
        ...state,
        isSubmitting: payload.isSubmitting,
        submitError: payload.submitError,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const openSurvey = surveyId => ({
  type: OPEN_SURVEY,
  payload: { surveyId },
});

export const closeSurvey = () => ({
  type: CLOSE_SURVEY,
});

export const setSurveyData = surveyData => ({
  type: SET_SURVEY_DATA,
  payload: { surveyData },
});

export const submitSurvey = (isSubmitting, submitError = null) => ({
  type: SUBMIT_SURVEY,
  payload: { isSubmitting, submitError },
});

// ================ Thunks ================ //

// In a real implementation, this would fetch survey data from an API
export const fetchSurveyData = surveyId => (dispatch, getState) => {
  // For now, we'll use hardcoded data

  // First try to get the survey data from the API
  return getSurveyById(surveyId)
    .then(surveyData => {
      console.log('🚀 ~ surveyData:', surveyData);
      dispatch(setSurveyData(surveyData));
    })
    .catch(error => {
      console.error('Error fetching survey data:', error);
    });

  // dispatch(setSurveyData(mockSurveyData));
};

// In a real implementation, this would submit survey responses to an API
export const submitSurveyResponses = data => async (dispatch, getState, sdk) => {
  dispatch(submitSurvey(true));
  // test
  axios
    .post(
      `${config.coachSaaSService}survey/submit-answers`,
      {
        surveyId: data.surveyId,
        answers: data.answers,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {
      console.log('Survey responses submitted:', res);
      dispatch(submitSurvey(false));
      dispatch(closeSurvey());
    })
    .catch(err => {
      console.log('🚀 ~ err:', err);
    });
};
