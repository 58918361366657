import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import css from './RequestDemoPopUp.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { enterpriseRequestDemo, setGotoBlockAutoplayValue } from '../../custom-ducks/utility.duck';
import { faCross, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notifySuccess } from '../../components/HotToast/HotToastProvider';

const RequestDemoPopUp = props => {
  const { trigger, onRequestDemo, onSetGotoBlockAutoplayValue } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <Popup
      closeOnDocumentClick={false}
      contentStyle={{ width: '600px', height: '700px' }}
      trigger={trigger}
      modal
      onOpen={() => onSetGotoBlockAutoplayValue(false)}
      onClose={() => onSetGotoBlockAutoplayValue(true)}
      lockScroll
    >
      {close => {
        const onSubmit = () => {
          onRequestDemo({
            firstName,
            lastName,
            company,
            email,
            phoneNumber,
          }).then(() => {
            notifySuccess('Demo Request Sent');
          });

          close();
        };


        return (
          <div className={css.form_base}>
            <div onClick={() => close()} className={css.close_button}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {/*<div*/}
            {/*  style={{height: 700, width: 500}}*/}
            {/*  className="pipedriveWebForms"*/}
            {/*  data-pd-webforms="https://webforms.pipedrive.com/f/1v6LGfu1DTiGMBKXFKPuZtVRvSjjqTfZZiYoAVWB3JhHdVHrCZrr3fzrpLFouiGbh"*/}
            {/*>*/}
            {/*</div>*/}

            {/*<iframe src={'https://webforms.pipedrive.com/f/1v6LGfu1DTiGMBKXFKPuZtVRvSjjqTfZZiYoAVWB3JhHdVHrCZrr3fzrpLFouiGbh'} height={'100%'} width={'100%'}/>*/}

            <div className={css.demo_form}>
              <label>First Name</label>
              <input
                className={css.field}
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
              />

              <label>Last Name</label>
              <input
                className={css.field}
                value={lastName}
                onChange={event => setLastName(event.target.value)}
              />

              <label>Company</label>
              <input
                className={css.field}
                value={company}
                onChange={event => setCompany(event.target.value)}
              />

              <label>Email</label>
              <input
                className={css.field}
                value={email}
                onChange={event => setEmail(event.target.value)}
              />

              <label>Phone number</label>
              <input
                className={css.field}
                value={phoneNumber}
                onChange={event => setPhoneNumber(event.target.value)}
              />

              <div onClick={onSubmit} className={css.button}>
                Submit
              </div>
            </div>
          </div>
        );
      }}
    </Popup>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onRequestDemo: data => dispatch(enterpriseRequestDemo(data)),
    onSetGotoBlockAutoplayValue: shouldGotoBlockAutoplay =>
      dispatch(setGotoBlockAutoplayValue(shouldGotoBlockAutoplay)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RequestDemoPopUp);
