import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { useSurvey } from '../../util/hooks/useSurvey';
import * as yup from 'yup';

const SurveyPopupComponent = props => {
  const { intl } = props;
  const {
    isOpen,
    surveyId,
    surveyData,
    isSubmitting,
    closeSurveyPopup,
    submitSurvey,
    fetchSurveyData,
  } = useSurvey();

  const [responses, setResponses] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    // Reset responses when survey changes
    setResponses({});
    setValidationErrors({});
    setIsValid(false);
    setShowErrors(false);
  }, [surveyId]);

  useEffect(() => {
    if (surveyId) {
      // Fetch survey data when surveyId changes
      fetchSurveyData(surveyId);
    }
  }, [surveyId, fetchSurveyData]);

  // Create validation schema based on survey questions
  const createValidationSchema = () => {
    if (!surveyData?.questions) return null;

    const schemaFields = {};
    surveyData.questions.forEach(question => {
      schemaFields[question.id] = yup.string().required('This question is required');
    });

    return yup.object().shape(schemaFields);
  };

  // Validate responses whenever they change
  useEffect(() => {
    const schema = createValidationSchema();
    if (!schema) return;

    schema
      .validate(responses, { abortEarly: false })
      .then(() => {
        setValidationErrors({});
        setIsValid(true);
      })
      .catch(err => {
        const errors = {};
        err.inner.forEach(error => {
          errors[error.path] = error.message;
        });
        setValidationErrors(errors);
        setIsValid(false);
      });
  }, [responses, surveyData?.questions]);

  const handleClose = () => {
    // Only allow closing if the survey is not required
    if (!surveyData?.isRequired) {
      closeSurveyPopup();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setShowErrors(true);
    if (!isValid) return;

    submitSurvey({
      surveyId,
      answers: responses,
    });
  };

  const handleRatingChange = (questionId, rating) => {
    setResponses(prevResponses => ({
      ...prevResponses,
      [questionId]: rating,
    }));
  };

  const handleTextChange = (questionId, e) => {
    const text = e.target.value;
    setResponses(prevResponses => ({
      ...prevResponses,
      [questionId]: text,
    }));
  };

  const renderRatingQuestion = question => {
    const selectedRating = responses[question.id];
    const error = showErrors ? validationErrors[question.id] : null;

    return (
      <div className="mb-6" key={question.id}>
        <div className="text-lg font-medium mb-2">{question.question}</div>
        <div className="flex space-x-3">
          {question.options.map(rating => (
            <button
              key={rating}
              type="button"
              className={classNames(
                'w-12 h-12 rounded-full flex items-center justify-center transition-all duration-200 text-lg font-medium',
                {
                  'bg-green-500 text-white shadow-md': selectedRating === rating,
                  'bg-white text-gray-700 border border-gray-200 hover:border-green-400 hover:text-green-500':
                    selectedRating !== rating,
                }
              )}
              onClick={() => handleRatingChange(question.id, rating)}
            >
              {rating}
            </button>
          ))}
        </div>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    );
  };

  const renderTextQuestion = question => {
    const response = responses[question.id] || '';
    const error = showErrors ? validationErrors[question.id] : null;

    return (
      <div className="mb-6" key={question.id}>
        <div className="text-lg font-medium mb-2">{question.question}</div>
        <textarea
          className={classNames(
            'w-full p-3 border rounded-md focus:outline-none focus:ring-2 box-border resize-none',
            {
              'border-red-500 focus:ring-red-500': error,
              'border-gray-300 focus:ring-blue-500': !error,
            }
          )}
          value={response}
          onChange={e => handleTextChange(question.id, e)}
          placeholder="Your answer"
          rows="4"
        />
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    );
  };

  const renderOptionsQuestion = question => {
    const selectedValue = responses[question.id];
    const error = showErrors ? validationErrors[question.id] : null;

    return (
      <div className="mb-6" key={question.id}>
        <div className="text-lg font-medium mb-2">{question.name}?</div>
        {question.description && <p className="text-gray-600 mb-2">{question.description}</p>}
        <div className="space-y-2">
          {question.options.map(option => (
            <label key={option.value} className="flex items-center space-x-3 cursor-pointer">
              <input
                type="radio"
                name={`question-${question.id}`}
                value={option.value}
                checked={selectedValue === option.value}
                onChange={() => handleRatingChange(question.id, option.value)}
                className="w-4 h-4 text-green-500 focus:ring-green-400"
              />
              <span className="text-gray-700">{option.label}</span>
            </label>
          ))}
        </div>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    );
  };

  const renderQuestion = question => {
    switch (question.type) {
      // case 'rating':
      //   return renderRatingQuestion(question);
      // case 'text':
      //   return renderTextQuestion(question);
      case 'options':
        return renderOptionsQuestion(question);
      default:
        return null;
    }
  };

  if (!isOpen || !surveyData) {
    return null;
  }

  //test build

  const { name: title, description, questions, isRequired } = surveyData;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full max-h-[90vh] overflow-y-auto overflow-x-hidden relative">
        {!isRequired && (
          <button
            className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center rounded-full text-gray-400 hover:text-gray-600 transition-colors duration-200 z-10"
            onClick={handleClose}
            type="button"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}

        <div className="p-6 pb-0 border-b border-gray-200">
          <h2 className="text-2xl font-bold mb-2">{title}</h2>
          <p className="text-gray-600">{description}</p>
          {isRequired && (
            <p className="text-red-500 mt-2 text-sm font-medium">
              This survey is required. Please complete it to continue.
            </p>
          )}
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="pr-2">{questions.map(question => renderQuestion(question))}</div>

          <div className="flex justify-end space-x-4 mt-6 pt-4 border-t border-gray-200">
            {!isRequired && (
              <button
                type="button"
                className="px-5 py-2.5 bg-white text-gray-700 rounded-md font-medium hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-200"
                onClick={handleClose}
              >
                Cancel
              </button>
            )}
            <button
              type="submit"
              className="px-5 py-2.5 bg-green-500 text-white rounded-md font-medium hover:bg-green-600 transition-colors duration-200 shadow-sm disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1"
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

SurveyPopupComponent.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SurveyPopupComponent);
