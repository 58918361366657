import axios from 'axios';
import config from '../../config';
import { getBearerToken } from '../../util/cookie';

export async function getSurveyByCurriculumId(curriculumId) {
  const survey = await axios.post(`${config.vcorpService}survey/getSurveyByCurriculumId`, {
    curriculumId: curriculumId,
  });

  return survey.data;
}

export async function getSurveyById(surveyId) {
  const survey = await axios.post(`${config.vcorpService}survey/getSurveyById`, {
    id: surveyId,
  });

  return survey.data;
}

export async function sendSurveyAnswers(surveyId, data, answeredUserId, pageData) {
  const survey = await axios.post(`${config.vcorpService}survey/sendSurveyAnswers`, {
    surveyId,
    data,
    answeredUserId,
    activity: pageData.activity,
    session: pageData.session,
  });

  return survey.data;
}
