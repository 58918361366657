/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import css from './Gallery.module.css';
import DashboardAddButton from '../../../elements/dashboard-add-button/DashboardAddButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight, faPlus, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { withRouter } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../components/HotToast/HotToastProvider';

function Gallery(props) {
  const { onPublishListingDraft, onImageUpload, onUpdateListing, images, listing } = props;
  const [videos, setVideos] = useState(['']);
  const [imageFields, setImageFields] = useState([{ id: '', src: '' }]);
  const [errors, setErrors] = useState([]);
  const [uploading, setUploading] = useState(false);

  const inputToState = (index, event) => {
    event.persist();
    let tempArray = videos.slice();
    tempArray[index] = event.target.value;
    setVideos(tempArray);
  };

  const addInput = () => {
    if (
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
        videos[videos.length - 1]
      )
    ) {
      let tempArray = videos.slice();
      tempArray[tempArray.length] = '';
      setVideos(tempArray);
    } else {
      notifyError('Add a valid video url to add another one');
    }
  };

  const removeInput = key => {
    if (videos.length > 1) {
      let tempArray = videos.slice();
      setVideos(tempArray.filter((x, index) => index !== key));
    } else {
      setVideos(['']);
    }
  };

  const removePic = key => {
    let tempArray = imageFields.filter(x => x.id.uuid !== key);
    if (tempArray.length > 0) {
      setImageFields(tempArray);
    } else {
      setImageFields([imageFields[0]]);
    }
  };

  const fileUpload = event => {
    const file = event.target.files[0];

    if (file) {
      setUploading(true);
      onImageUpload({
        id: `${file.name}_${Date.now()}`,
        file,
      }).then(res => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', thumb => {
          let tempArray = imageFields.slice();
          tempArray[tempArray.length] = {
            id: res.data.data.id,
            src: thumb.target.result,
          };
          setImageFields(tempArray);
          setUploading(false);
        });
      });
    }
  };

  const getImageFromState = () => {
    let tempArray = [];
    for (let i = 0; i < imageFields.length; i++) {
      tempArray[i] = imageFields[i].id;
    }
    return tempArray;
  };

  const validateFields = () => {
    let validationErrors = [];
    let isValid = true;

    let videosAreValid = true;
    let errorIn = [];

    for (let i = 0; i < videos.length; i++) {
      const isNotValid =
        !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
          videos[i]
        ) && i !== 0;
      if (isNotValid) {
        if (videosAreValid) {
          videosAreValid = false;
        }
        errorIn[errorIn.length] = i + 1;
      }
    }

    if (!videosAreValid) {
      validationErrors.push(`Invalid video url(s), Check video fields ${errorIn.join(', ')}`);
      setErrors(validationErrors);
      isValid = false;
    }

    if (imageFields.length < 1) {
      validationErrors.push('Add at least 1 photo');
      setErrors(validationErrors);
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    let tempObj = { publicData: {} };
    if (imageFields.length > 0) {
      tempObj.images = getImageFromState();
    }

    if (videos.length > 0) {
      tempObj.publicData.videos = videos;
    }

    if (validateFields()) {
      onUpdateListing('gallery', { ...tempObj, id: listing.id }).then(res => {
        //publish if not already
        onPublishListingDraft(listing.id).then(() => {
          props.history.push(`/listings`);
          notifySuccess('Saved');
        });
      });
    } else {
      notifyError('Has non valid inputs');
    }
  };

  useEffect(() => {
    let tempArray = [];
    for (let i = 0; i < images.length; i++) {
      tempArray[i] = { id: images[i].id, src: images[i].attributes.variants['landscape-crop'].url };
    }
    setImageFields(tempArray);

    if (listing.attributes.publicData && listing.attributes.publicData.videos) {
      setVideos(listing.attributes.publicData.videos);
    }
  }, []);

  return (
    <div className={css.wrapper}>
      <div className={css.video}>
        <p>Add video URL</p>
        {videos.map((item, index) => {
          return (
            <div key={index}>
              <div className={css.inputContainer}>
                <input
                  className={css.input}
                  input="text"
                  value={item}
                  onChange={e => inputToState(index, e)}
                />
                <div className={css.remove} onClick={() => removeInput(index)}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
              {videos.length === index + 1 && (
                <div onClick={() => addInput()} className={css.addButton}>
                  {/*<DashboardAddButton onClick={() => addInput()} />*/}
                  <FontAwesomeIcon  icon={faPlusSquare} />
                  <div>Add URL</div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className={css.video}>
        <p>Add photos</p>
        <div className={css.imageView}>
          {imageFields.map((item, index) => (
            <div key={index}>
              <img alt="listing" src={item.src} />
              <div className={css.removePic} onClick={() => removePic(item.id.uuid)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ))}
          <div>
            <input id="ListingImagePicker" type="file" accept="image/*" onChange={fileUpload} />
            <label htmlFor="ListingImagePicker">
              {uploading ? (
                <p>Uploading</p>
              ) : (
                <FontAwesomeIcon className={css.imagePickerIcon} icon={faPlus} />
              )}
            </label>
          </div>
        </div>
      </div>
      <div className={css.errors}>
        {errors.map((item, index) => (
          <p key={index} className={css.error}>
            {item}
          </p>
        ))}
      </div>
      {/*<div className={css.next}>*/}
      {/*  <p>Save & Submit</p>*/}
      {/*  <DashboardAddButton onClick={() => onSubmit()} icon={faArrowRight} />*/}
      {/*</div>*/}
      <div onClick={() => onSubmit()} className={css.button}>
        Submit
      </div>
    </div>
  );
}

export default withRouter(Gallery);
