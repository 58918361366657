/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import css from './Overview.module.css';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Multiselect } from 'multiselect-react-dropdown';
import Geocoder from '../../../../components/LocationAutocompleteInput/GeocoderMapbox';
import { languages } from '../../../../marketplace-custom-config';
import axios from 'axios';

import DashboardAddButton from '../../../elements/dashboard-add-button/DashboardAddButton';
import config from '../../../../config';
import { getAllPrograms } from '../../../../custom-ducks/programs.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { SaaSProgramsPageComponent } from '../../../../containers/SaaSProgramsPage/SaaSProgramsPage';
import ReactQuill from 'react-quill';
import ReactHtmlParser from 'react-html-parser';
import { encode, decode } from 'html-entities';
import { notifyError, notifySuccess } from '../../../../components/HotToast/HotToastProvider';

function Overview(props) {
  const {
    onCreateListingDraft,
    onUpdateListing,
    listing,
    currentUser,
    onGetAllPrograms,
    programs,
    setTabIndex,
  } = props;
  const [inputs, setInputs] = useState({
    category: 'coach',
    title: '',
    // description: '',
    language: [],
    location: '',

    program: 'select',
  });
  const [description, setDescription] = useState();
  const [geo, setGeo] = useState({ lat: 1, lng: 1 });
  const [locations, setLocations] = useState([]);
  const [errors, setErrors] = useState([]);

  const isNew = () => {
    return listing.id === null;
  };

  useEffect(() => {
    if (!isNew()) {
      setInputs({
        ...inputs,
        category:
          listing.attributes.publicData && listing.attributes.publicData.category
            ? listing.attributes.publicData.category
            : 'coach',
        title: listing.attributes.title || '',
        // description: listing.attributes.description || '',
        language: listing.attributes.publicData.language || '',
        location:
          listing.attributes.publicData && listing.attributes.publicData.location
            ? listing.attributes.publicData.location.address
            : '',
        program:
          listing.attributes.publicData && listing.attributes.publicData.programId
            ? listing.attributes.publicData.programId
            : '',
      });
      setDescription(listing.attributes.description);
    }

    if (listing.attributes.geolocation) {
      setGeo({ lat: listing.attributes.geolocation.lat, lng: listing.attributes.geolocation.lng });
    }

    onGetAllPrograms();
  }, []);

  const dropdownStyle = {
    chips: { background: '#007373', borderRadius: '8px', minHeight: '40px' },
    searchBox: {
      border: '1px solid rgba(144, 144, 144, 0.705)',
      borderRadius: '9px',
      minHeight: '50px',
    },
    option: { backgroundColor: 'white', color: '#666666' },
  };

  const geocoder = new Geocoder();

  const onLocationChange = e => {
    e.persist();
    setInputs({ ...inputs, location: e.target.value });
    geocoder
      .getPlacePredictions(e.target.value && e.target.value.length > 0 ? e.target.value : '')
      .then(res => {
        setLocations(res.predictions);
        if (res.predictions.length > 0) {
          setGeo({ lat: res.predictions[0].center[1], lng: res.predictions[0].center[0] });
        }
      })
      .catch(err => {
        console.log(err);
        setLocations([]);
      });
  };

  const multiselectToState = e => {
    let tempArray = [];
    for (let index = 0; index < e.length; index++) {
      tempArray.push(e[index].key);
    }
    return tempArray;
  };

  const validateFields = () => {
    let validationErrors = [];
    let isValid = true;
    const { title, language, location, program } = inputs;

    if (title.length < 10) {
      validationErrors.push('Title should at least be 10 characters long.');
      setErrors(validationErrors);
      isValid = false;
    }

    if (program && program == 'select') {
      validationErrors.push('A Program needs to be selected');
      setErrors(validationErrors);
      isValid = false;
    }

    const formattedDescription = decode(description).replace(/<[^>]+>/g, '');
    if (formattedDescription.length <= 500) {
      validationErrors.push('Description should at least be 500 characters long.');
      setErrors(validationErrors);
      isValid = false;
    }

    if (language.length < 1) {
      validationErrors.push('Add at least 1 language.');
      setErrors(validationErrors);
      isValid = false;
    }

    if (location.length < 1) {
      validationErrors.push('Add a location.');
      setErrors(validationErrors);
      isValid = false;
    }

    return isValid;
  };

  const onCreateDraft = () => {
    let tempObj = { publicData: {} };

    tempObj.publicData.author = currentUser
      ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      : '';

    if (inputs.title) {
      tempObj.title = inputs.title;
    }

    if (inputs.program) {
      tempObj.publicData.programId = inputs.program;
    }

    // if (inputs.description) {
    //   tempObj.description = inputs.description;
    // }

    if (description) {
      tempObj.description = description;
    }

    if (geo) {
      tempObj.geolocation = geo;
    }

    if (inputs.location) {
      tempObj.publicData.location = { address: inputs.location };
    }

    if (inputs.language) {
      tempObj.publicData.language = inputs.language;
    }

    if (inputs.category) {
      tempObj.publicData.category = inputs.category;
    }

    console.log({ tempObj });

    const callListingService = id => {
      axios
        .post(
          `${config.listingService}set-premium`,
          { id: id },
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        )
        .then(res => {
          console.log(res);
        });
    };

    // console.log(tempObj);
    if (validateFields()) {
      console.log('🚀 ~ onCreateDraft ~ validateFields:', validateFields);
      // createOrUpdateContact({
      //   email: currentUser.attributes.email,
      //   has_created_a_listing: true,
      // });

      if (isNew()) {
        onCreateListingDraft(tempObj).then(res => {
          setErrors([]);
          callListingService(res.data.data.id.uuid);
          props.history.push(`/l/edit/${res.data.data.id.uuid}/edit/gallery`);
          notifySuccess('Created');
          setTabIndex(1);
        });
      } else {
        onUpdateListing('description', { ...tempObj, id: listing.id }).then(res => {
          setErrors([]);
          props.history.push(`/l/edit/${res.data.data.id.uuid}/edit/gallery`);
          notifySuccess('Saved');
          setTabIndex(1);
        });
      }
    } else {
      console.log('Has non valid input(s)');
      notifyError('Has non valid input(s)');
    }
  };

  const getMultiSelectedValues = name => {
    let tempArray = [];
    if (listing.attributes.publicData[name]) {
      for (let i = 0; i < listing.attributes.publicData[name].length; i++) {
        tempArray[i] = {
          key: listing.attributes.publicData[name][i],
          label: listing.attributes.publicData[name][i],
        };
      }
    }
    return tempArray;
  };

  const onDescriptionChange = html => {
    const htmlText = html.replace(/<[^>]+>/g, '');

    if (htmlText.length <= 3000) {
      setDescription(encode(html));
    }
  };

  const src =
    'https://api.mapbox.com/styles/v1/mapbox/streets-v10/static' +
    `/pin-s(${geo.lng},${geo.lat})` +
    `/${geo.lng},${geo.lat},${4}` +
    `/${1200}x${600}` +
    `?access_token=${config.maps.mapboxAccessToken}`;

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [
        'link',
        // 'image'
      ],
      ['clean'],
    ],
  };

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    // 'image',
  ];

  const descriptionOnlyText = decode(description).replace(/<[^>]+>/g, '');

  return (
    <div className={css.wrapper}>
      <div className={css.category}>
        <p className={css.label}>Selected category</p>
        <div className={css.categories}>
          <div
            onClick={() => setInputs({ ...inputs, category: 'coach' })}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.category === 'coach' ? css.selectedCategory : ''
              }`}
            />
            <div>Coach</div>
          </div>

          <div
            onClick={() => setInputs({ ...inputs, category: 'speaker' })}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.category === 'speaker' ? css.selectedCategory : ''
              }`}
            />
            <div>Speaker</div>
          </div>

          <div
            onClick={() => setInputs({ ...inputs, category: 'mentor' })}
            style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
          >
            <div
              className={`${css.select_dot} ${
                inputs.category === 'mentor' ? css.selectedCategory : ''
              }`}
            />
            <div>Mentor</div>
          </div>
        </div>
      </div>
      <div className={css.title}>
        <p className={css.label}>Listing title</p>
        <input
          value={inputs.title}
          onChange={e => setInputs({ ...inputs, title: e.target.value.substring(0, 150) })}
          className={css.input}
          type="text"
        />
        <p className={css.info}>{inputs.title.length}/150 max</p>
      </div>

      <div className={css.program}>
        <p className={css.label}>Select a program</p>
        <select
          value={inputs.program}
          onChange={e => setInputs({ ...inputs, program: e.target.value })}
        >
          <option className={css.label} value="select">
            Select A Program
          </option>
          {programs &&
            programs.map((item, index) => {
              return (
                <option className={css.label} key={index} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </div>

      <div className={css.description}>
        <p className={css.label}>Detailed description</p>
        {/*        <textarea*/}
        {/*          value={inputs.description}*/}
        {/*          onChange={e => setInputs({ ...inputs, description: e.target.value.substring(0, 3000) })}*/}
        {/*          className={css.input}*/}
        {/*          rows="10"*/}
        {/*          placeholder={`*/}
        {/*Describe your service for new clients. Focus on:*/}

        {/*1.The outcomes & results you produce for clients - WIIFM 'What's In It For Me'*/}
        {/*2.Testimonials - the success you have brought other clients.*/}
        {/*3.Lastly, mention your process.`}*/}
        {/*        />*/}

        <ReactQuill
          value={decode(description)}
          onChange={e => onDescriptionChange(e)}
          modules={quillModules}
          formats={quillFormats}
        />
        <p className={css.info}>{descriptionOnlyText.length}/3000 max</p>
      </div>
      <div className={css.language}>
        <p className={css.label}>Language</p>
        <Multiselect
          onRemove={e => setInputs({ ...inputs, language: multiselectToState(e) })}
          onSelect={e => setInputs({ ...inputs, language: multiselectToState(e) })}
          style={dropdownStyle}
          options={languages}
          displayValue="label"
          selectedValues={getMultiSelectedValues('language')}
        />
      </div>
      <div className={css.location}>
        <p className={css.label}>City</p>
        <input
          list="addListingLocation"
          value={inputs.location}
          onChange={onLocationChange}
          className={css.input}
          type="text"
        />
        <datalist id="addListingLocation">
          {locations.map((item, index) => (
            <option className={css.locationOptions} key={index}>
              {item.place_name_en}
            </option>
          ))}
        </datalist>
      </div>
      <div className={css.map}>
        <img alt={'map'} src={src} />
      </div>
      <div className={css.errors}>
        {errors.map((item, index) => (
          <p key={index} className={css.error}>
            {item}
          </p>
        ))}
      </div>
      {/*<div className={css.next}>*/}
      {/*  <p>Save & Continue</p>*/}
      {/*  <DashboardAddButton onClick={() => onCreateDraft()} icon={faArrowRight} />*/}
      {/*</div>*/}
      <div onClick={() => onCreateDraft()} className={css.button}>
        Continue
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { programs, programs_error, programs_fetching } = state.programs;
  return {
    programs:
      programs &&
      programs.map(x => {
        return { id: x.id, name: x.name };
      }),
    programs_error,
    programs_fetching,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAllPrograms: () => {
    dispatch(getAllPrograms());
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Overview);
