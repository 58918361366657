import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  // Page,
  // LayoutSideNavigation,
  // LayoutWrapperMain,
  // LayoutWrapperSideNav,
  // LayoutWrapperTopbar,
  // LayoutWrapperFooter,
  // AvatarLarge,
  // NamedLink,
  // ListingCard,
  Reviews,
  // ButtonTabNavHorizontal,
} from '../../components';
// import { NotFoundPage } from '../../containers';
import { loadData } from './ProfilePage.duck';
// import config from '../../config';

import css from './ProfilePage.css';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import ProfileMainPicture
  from '../../custom-components/elements/profile-main-picture/ProfileMainPicture';
import ProfileMainBio from '../../custom-components/elements/profile-main-right/ProfileMainBio';
// import ProfileCard from '../../custom-components/elements/profile-card/ProfileCard';
// import ReviewItem from '../../custom-components/elements/review-item/ReviewItem';
// import ProfileUserBadge from '../../custom-components/elements/profile-user-badge/ProfileUserBadge';
// import Button from '../../custom-components/elements/Button/Button';
// import ProfileServiceTypes
//   from '../../custom-components/elements/profile-service-types/ProfileServiceTypes';
// import { array } from '@storybook/addon-knobs';
// import ProfileLanguages from '../../custom-components/elements/profile-languages/ProfileLanguages';
// import ProfileMeetingTypes
//   from '../../custom-components/elements/profile-meeting-types/ProfileMeetingTypes';
// import AccreditationItem
//   from '../../custom-components/elements/accreditation-item/AccreditationItem';
// import ServiceDeliveryTo
//   from '../../custom-components/elements/service-delivery-to/ServiceDeliveryTo';
// import ProfileVideo from '../../custom-components/elements/profile-video/ProfileVideo';
// import UserBadges from '../../custom-components/elements/user-badges/UserBadges';
import Footer from '../../custom-components/elements/footer/Footer';
import UsersListingWrapper
  from '../../custom-components/wrappers/users-listing-wrapper/UsersListingWrapper';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';
import PublicPageWrapper from '../../components/PublicPageWrapper/PublicPageWrapper';
// import { getOwnListingsById } from '../ManageListingsPage/ManageListingsPage.duck';

const { UUID } = sdkTypes;
// const MAX_MOBILE_SCREEN_WIDTH = 768;

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  render() {
    const {
      // scrollingDisabled,
      // currentUser,
      user,
      // userShowError,
      // queryListingsError,
      listings,
      reviews,
      // queryReviewsError,
      // viewport,
      // intl,
    } = this.props;
    // const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    // const isCurrentUser =
    //   ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const displayName = profileUser.attributes.profile.displayName;
    const bio = profileUser.attributes.profile.bio;

    let location = '';
    if(profileUser.attributes.profile.publicData){
      location = profileUser.attributes.profile.publicData.location;
    }

    let profileImage = '';
    if(profileUser.profileImage){
      profileImage = profileUser.profileImage.attributes.variants['scaled-large'].url;
    }


    // const hasBio = !!bio;
    // const hasListings = listings.length > 0;
    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;



    // const editLinkMobile = isCurrentUser ? (
    //   <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
    //     <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
    //   </NamedLink>
    // ) : null;
    // const editLinkDesktop = isCurrentUser ? (
    //   <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
    //     <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
    //   </NamedLink>
    // ) : null;

    // const asideContent = (
    //   <div className={css.asideContent}>
    //     <AvatarLarge className={css.avatar} user={user} disableProfileLink />
    //     <h1 className={css.mobileHeading}>
    //       {displayName ? (
    //         <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
    //       ) : null}
    //     </h1>
    //     {editLinkMobile}
    //     {editLinkDesktop}
    //   </div>
    // );

    // const listingsContainerClasses = classNames(css.listingsContainer, {
    //   [css.withBioMissingAbove]: !hasBio,
    // });

    // const reviewsError = (
    //   <p className={css.error}>
    //     <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
    //   </p>
    // );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    // const mobileReviews = (
    //   <div className={css.mobileReviews}>
    //     <h2 className={css.mobileReviewsTitle}>
    //       <FormattedMessage
    //         id="ProfilePage.reviewsOfProviderTitle"
    //         values={{ count: reviewsOfProvider.length }}
    //       />
    //     </h2>
    //     {queryReviewsError ? reviewsError : null}
    //     <Reviews reviews={reviewsOfProvider} />
    //     <h2 className={css.mobileReviewsTitle}>
    //       <FormattedMessage
    //         id="ProfilePage.reviewsOfCustomerTitle"
    //         values={{ count: reviewsOfCustomer.length }}
    //       />
    //     </h2>
    //     {queryReviewsError ? reviewsError : null}
    //     <Reviews reviews={reviewsOfCustomer} />
    //   </div>
    // );

    // const desktopReviewTabs = [
    //   {
    //     text: (
    //       <h3 className={css.desktopReviewsTitle}>
    //         <FormattedMessage
    //           id="ProfilePage.reviewsOfProviderTitle"
    //           values={{ count: reviewsOfProvider.length }}
    //         />
    //       </h3>
    //     ),
    //     selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
    //     onClick: this.showOfProviderReviews,
    //   },
    //   {
    //     text: (
    //       <h3 className={css.desktopReviewsTitle}>
    //         <FormattedMessage
    //           id="ProfilePage.reviewsOfCustomerTitle"
    //           values={{ count: reviewsOfCustomer.length }}
    //         />
    //       </h3>
    //     ),
    //     selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
    //     onClick: this.showOfCustomerReviews,
    //   },
    // ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        {/*<ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <Reviews reviews={reviewsOfProvider} />
        ) : (
          <Reviews reviews={reviewsOfCustomer} />
        )}*/}

        <Reviews reviews={reviewsOfProvider} />
        <Reviews reviews={reviewsOfCustomer} />
      </div>
    );

    // const mainContent = (
    //   <div>
    //     <h1 className={css.desktopHeading}>
    //       <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
    //     </h1>
    //     {hasBio ? <p className={css.bio}>{bio}</p> : null}
    //     {hasListings ? (
    //       <div className={listingsContainerClasses}>
    //         <h2 className={css.listingsTitle}>
    //           <FormattedMessage
    //             id="ProfilePage.listingsTitle"
    //             values={{ count: listings.length }}
    //           />
    //         </h2>
    //         <ul className={css.listings}>
    //           {listings.map(l => (
    //             <li className={css.listing} key={l.id.uuid}>
    //               <ListingCard listing={l} />
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //     ) : null}
    //     {isMobileLayout ? mobileReviews : desktopReviews}
    //   </div>
    // );

    // let content;
    //
    // if (userShowError && userShowError.status === 404) {
    //   return <NotFoundPage />;
    // } else if (userShowError || queryListingsError) {
    //   content = (
    //     <p className={css.error}>
    //       <FormattedMessage id="ProfilePage.loadingDataFailed" />
    //     </p>
    //   );
    // } else {
    //   content = mainContent;
    // }

    // const schemaTitle = intl.formatMessage(
    //   {
    //     id: 'ProfilePage.schemaTitle',
    //   },
    //   {
    //     name: displayName,
    //     siteTitle: config.siteTitle,
    //   }
    // );

    const profileBio = () => {
      if(profileUser && profileUser.attributes.profile.metadata){
        let userMeta = profileUser.attributes.profile.metadata;

        return (
          <ProfileMainBio
            name={displayName}
            bio={bio}
            location={location}
            rating={4}
            reviewCount={10}
            user={profileUser}
            is_premium={userMeta.premium}
            is_best={userMeta.is_best}
            is_top={userMeta.is_top}
            is_explosive={userMeta.is_explosive}
            is_guru={userMeta.is_guru}
          />
        )
      }
    }

    return (
      <PageWrapper
        title={`${displayName} | Viveka`}
        image={profileImage}
        className={css.page_wrapper}
        description={bio && bio.substring(0, 250)}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: `${displayName} | Viveka`,
          image: profileImage,
        }}
      >
        <PublicPageWrapper page={'page-enterprise'}>
          <ContainerWrapper>
            <div className={css.profile_container}>
              <div className={css.profileLeft}>
                <div className={css.profile_left_padded}>
                  <ProfileMainPicture profilePic={profileImage} ratingCommunication={3} ratingResponseTime={5} ratingService={4}
                    user={profileUser}
                  />

                  {/*{isMobileLayout ? mobileReviews : desktopReviews}*/}

                  {desktopReviews}


                  {/*<div className={css.reviews_container}>
                    <ReviewItem profilePic={"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQsg6KXHm6o4SYhPycSwdsJnlGOWCVyIhWBWcg5y8_pki6coDzG&usqp=CAU"}
                                name={"Customer 1"} review={"Thats a fantastic session. Would love to have it again"}
                                flag={"https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=100&q=80"}/>

                    <ReviewItem profilePic={"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQsg6KXHm6o4SYhPycSwdsJnlGOWCVyIhWBWcg5y8_pki6coDzG&usqp=CAU"}
                                name={"Customer 2"} review={"Thats a fantastic session. Would love to have it again"}
                                flag={"https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=100&q=80"}/>

                    <ReviewItem profilePic={"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQsg6KXHm6o4SYhPycSwdsJnlGOWCVyIhWBWcg5y8_pki6coDzG&usqp=CAU"}
                                name={"Customer 3"} review={"Thats a fantastic session. Would love to have it again"}
                                flag={"https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=100&q=80"}/>

                    <Button text={"see more"} type={"text_with_border"}/>
                  </div>*/}

                  {/*<div className={css.profile_badge_stack}>
                    <ProfileUserBadge badge={1} label={"Top Coach"} size={"medium"}/>
                    <ProfileUserBadge badge={2} label={"Top Level Service"} size={"medium"}/>
                    <ProfileUserBadge badge={3} label={"Top Endorsed"} size={"medium"}/>
                    <ProfileUserBadge badge={4} label={"Certified Coach"} size={"medium"}/>
                  </div>*/}

                  {/*<div className={css.profile_left_section}>
                    <h2 className={css.section_title}>
                      <FormattedMessage id="ListingPage.type_of_service" />
                    </h2>
                    <ProfileServiceTypes  services={array("services",["Career", "Confidence", "Leadership", "Life", "Technology", "Wellness"])} limit={4}/>
                  </div>
                  <div className={css.profile_left_section}>
                    <h2 className={css.section_title}>
                      <FormattedMessage id="ListingPage.language" />
                    </h2>
                    <ProfileLanguages languages={array("languages",["English", "French", "Spanish"])} limit={2}/>
                  </div>

                  <div className={css.profile_left_section}>
                    <h2 className={css.section_title}>
                      <FormattedMessage id="ListingPage.meeting_type" />
                    </h2>
                    <ProfileMeetingTypes meetings={array("meetings", ["Remote", "In Person"])}/>
                  </div>

                  <div className={css.profile_accreditation}>
                    <h2 className={css.section_title}>
                      <FormattedMessage id="ListingPage.accreditation" />
                    </h2>
                    <div className={css.profile_accreditation_list}>
                      <AccreditationItem title={"Bachelor of Aviation Management."} start={2010} end={2012}/>
                      <AccreditationItem title={"Bachelor of Aviation Management."} start={2010} end={2012}/>
                      <AccreditationItem title={"Bachelor of Aviation Management."} start={2010} end={2012}/>
                    </div>
                  </div>

                  <div className={css.profile_left_section}>
                    <ServiceDeliveryTo deliverTypes={array("deliverTypes", ["Individual", "Small Group"])}/>
                  </div>*/}
                </div>




              </div>

              <div className={css.profileRight}>
                <div className={css.profile_right_inner}>

                  {profileBio()}

                  <UsersListingWrapper listings={listings}/>

                  {/*<div className={css.profile_assets}>
                        <ProfileVideo placeholder={"http://mr-geek.com/viveka/placeholder_video.png"}/>
                      </div>*/}
                </div>

              </div>

            </div>
          </ContainerWrapper>
          <ContainerWrapper>
            <Footer />
          </ContainerWrapper>
        </PublicPageWrapper>
      </PageWrapper>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
  } = state.ProfilePage;

  //const listings = getOwnListingsById(state, currentPageResultIds);

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId, include: ['profile.metadata']}]);
  const user = userMatches.length === 1 ? userMatches[0] : null;

  const listings = getMarketplaceEntities(state, userListingRefs);


  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
  };
};

const ProfilePage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
