import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  openSurvey,
  closeSurvey,
  submitSurveyResponses,
  fetchSurveyData as fetchSurveyDataAction,
} from '../../custom-ducks/survey.duck';

/**
 * Custom hook for survey functionality
 *
 * @returns {Object} Survey methods and state
 */
export const useSurvey = () => {
  const dispatch = useDispatch();

  // Get survey state from Redux
  const { isOpen, surveyId, surveyData, isSubmitting } = useSelector(state => state.survey);

  // Open survey
  const openSurveyPopup = useCallback(
    id => {
      if (!id) {
        console.error('Survey ID is required to open a survey popup');
        return;
      }
      dispatch(openSurvey(id));
    },
    [dispatch]
  );

  // Close survey
  const closeSurveyPopup = useCallback(() => {
    dispatch(closeSurvey());
  }, [dispatch]);

  // Submit survey responses
  const submitSurvey = useCallback(
    responses => {
      dispatch(submitSurveyResponses(responses));
    },
    [dispatch]
  );

  // Fetch survey data
  const fetchSurveyData = useCallback(
    id => {
      dispatch(fetchSurveyDataAction(id));
    },
    [dispatch]
  );

  return {
    isOpen,
    surveyId,
    surveyData,
    isSubmitting,
    openSurveyPopup,
    closeSurveyPopup,
    submitSurvey,
    fetchSurveyData,
  };
};
