import React, { Component, useEffect, useState } from 'react';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';
import SettingsLayoutWrapper from '../../custom-components/wrappers/settings-layout-wrapper/SettingsLayoutWrapper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './CoachBrandingPage.css';
import { getAllMyActivities } from '../../custom-ducks/myActivities.duck';
import { sendTestCoachEmail } from '../../custom-ducks/settings.duck';
import ReactQuill from 'react-quill';
import { decode } from 'html-entities';
import { Loader } from 'react-feather';
import { IconSpinner } from '../../components';
import { notifySuccess } from '../../components/HotToast/HotToastProvider';

const CoachBranding = ({ getSdk, currentUser, onSendTestCoachEmail }) => {
  const [emailSending, setEmailSending] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [promo, setPromo] = useState();
  const [footer, setFooter] = useState();
  const sdk = getSdk();

  // console.log({currentUser});

  useEffect(() => {
    setImageUrl(currentUser?.attributes?.profile?.privateData?.emailBrand?.logo);
    setPromo(currentUser?.attributes?.profile?.privateData?.emailBrand?.promo);
    setFooter(currentUser?.attributes?.profile?.privateData?.emailBrand?.footer);
  }, [currentUser]);

  const save = () => {
    return sdk.currentUser.updateProfile({
      privateData: {
        emailBrand: {
          logo: imageUrl,
          promo: promo,
          footer: footer,
        },
      },
    });
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [
        'link',
        // 'image'
      ],
      ['clean'],
    ],
  };

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    // 'image',
  ];

  return (
    <PageWrapper>
      <MainDashboardWrapper title={'Coach Branding'} page={'all-setting'}>
        <SettingsLayoutWrapper page="branding">
          {/*<input />*/}
          {/*<br/>*/}
          {/*<input />*/}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '700px',
            }}
          >
            <p className={css.title}>Coach Email Template</p>
            <p
              style={{
                margin: 0,
                fontWeight: 600,
              }}
            >
              Logo
            </p>
            {imageUrl && <img src={imageUrl} alt="Image" className={css.upload_image} />}
            <input
              type="file"
              onChange={e => {
                const file = e.target.files[0];

                sdk.images
                  .upload(
                    {
                      image: file,
                    },
                    {
                      expand: true,
                    }
                  )
                  .then(res => {
                    // res.data
                    const image = res?.data?.data?.attributes?.variants?.default?.url;
                    // console.log({image});
                    setImageUrl(image);
                  });
              }}
              accept="image/png, image/jpeg"
            />
          </div>

          <div className={css.input_cover}>
            <p>Email Footer</p>
            <ReactQuill
              value={decode(footer)}
              onChange={value => {
                setFooter(value);
              }}
              modules={quillModules}
              formats={quillFormats}
            />
          </div>

          <div className={css.input_cover}>
            <p>Promotion</p>
            {/*<input value={promo} onChange={(value) => {*/}
            {/*  console.log(value);*/}
            {/*  // setPromo(value);*/}
            {/*}}/>*/}
            <ReactQuill
              value={decode(promo)}
              onChange={value => {
                setPromo(value);
              }}
              modules={quillModules}
              formats={quillFormats}
            />

            <div
              style={{
                display: 'flex',
                gap: '20px',
              }}
            >
              <button
                className={css.button}
                onClick={() => {
                  save().then(res => {
                    notifySuccess('Saved')
                  });
                }}
              >
                Save
              </button>

              <button
                className={css.button_gray}
                onClick={() => {
                  setEmailSending(true);
                  save()
                    .then(() => {
                      onSendTestCoachEmail(currentUser?.attributes?.email)
                        .then(() => {
                          setEmailSending(false);
                          notifySuccess('Email Sent')
                        })
                        .catch(() => {
                          setEmailSending(false);
                        });
                    })
                    .catch(() => {
                      setEmailSending(false);
                    });
                }}
                disabled={emailSending}
              >
                {emailSending ? (
                  <IconSpinner />
                ) : (
                  <span>Send a sample email to {currentUser?.attributes?.email}</span>
                )}
              </button>
            </div>
          </div>
        </SettingsLayoutWrapper>
      </MainDashboardWrapper>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  // updateCoachBrandingD: values => {
  //   return dispatch(updateCoachBranding(values));
  // },

  onSendTestCoachEmail: email => {
    return dispatch(sendTestCoachEmail(email));
  },
  getSdk: () => {
    return dispatch((dispatch, getState, sdk) => {
      return sdk;
    });
  },
});

const CoachBrandingPage = compose(connect(mapStateToProps, mapDispatchToProps))(CoachBranding);

export default CoachBrandingPage;
