/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  // LINE_ITEM_NIGHT,
  // LINE_ITEM_DAY,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  // LISTING_PAGE_PARAM_TYPE_DRAFT,
  // LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
// import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  // Page,
  // NamedLink,
  NamedRedirect,
  // LayoutSingleColumn,
  // LayoutWrapperTopbar,
  // LayoutWrapperMain,
  // LayoutWrapperFooter,
} from '../../components';
import { NotFoundPage } from '../../containers';
import Footer from '../../custom-components/elements/footer/Footer';

import { sendEnquiry, loadData, setInitialValues } from './ListingPage.duck';
// import SectionImages from './SectionImages';
// import SectionAvatar from './SectionAvatar';
// import SectionHeading from './SectionHeading';
// import SectionDescriptionMaybe from './SectionDescriptionMaybe';
// import SectionFeaturesMaybe from './SectionFeaturesMaybe';
// import SectionReviews from './SectionReviews';
// import SectionHostMaybe from './SectionHostMaybe';
// import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.css';
import PremiumProfileBio from '../../custom-components/elements/premium-profile-bio/PremiumProfileBio';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import ListingProfileRight from '../../custom-components/elements/listing-profile-right/ListingProfileRight';
// import Gallery from '../../custom-components/elements/gallery/Gallery';
// import ProfileVideo from '../../custom-components/elements/profile-video/ProfileVideo';
import ProfileLanguages from '../../custom-components/elements/profile-languages/ProfileLanguages';
import ProfileMeetingTypes from '../../custom-components/elements/profile-meeting-types/ProfileMeetingTypes';
// import ProfileServiceTypes from '../../custom-components/elements/profile-service-types/ProfileServiceTypes';
import ServiceDeliveryTo from '../../custom-components/elements/service-delivery-to/ServiceDeliveryTo';
import ListingProfileMobile from '../../custom-components/elements/listing-profile-right/ListingProfileMobile';
// import BookingPanel from '../../components/BookingPanel/BookingPanel';

import SkyLight from 'react-skylight';
import BookingModal from '../../custom-components/elements/listing-profile-right/bookingModal/BookingModal';
// import SideChat from '../../custom-components/elements/side-chat/SideChat';
import { addChat } from '../../custom-ducks/socketChat.duck';
import PublicPageWrapper from '../../components/PublicPageWrapper/PublicPageWrapper';
import { notifyError } from '../../components/HotToast/HotToastProvider';

// const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

// const priceData = (price, intl) => {
//   if (price && price.currency === config.currency) {
//     const formattedPrice = formatMoney(intl, price);
//     return { formattedPrice, priceTitle: formattedPrice };
//   } else if (price) {
//     return {
//       formattedPrice: `(${price.currency})`,
//       priceTitle: `Unsupported currency (${price.currency})`,
//     };
//   }
//   return {};
// };

// const categoryLabel = (categories, key) => {
//   const cat = categories.find(c => c.key === key);
//   return cat ? cat.label : key;
// };

export class ListingPageComponent extends Component {
  authorOfTheListing;

  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      userOnline: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.setupSocket.bind(this);
  }

  setupSocket() {
    const { socket } = this.props;
    if (!socket) return;
    socket.emit('userStatus', this.authorOfTheListing.id.uuid);
    socket.on('userStatus', isAvailable => {
      if (isAvailable) {
        this.setState({ userOnline: true });
      } else {
        this.setState({ userOnline: false });
      }
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    // this.setupSocket();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    var rightColumn = document.querySelector('#right_column');
    var navBarWrapper = document.querySelector('#nav_bar_wrapper');
    if (rightColumn) {
      var rightColumnHeight = rightColumn.getBoundingClientRect().height;
      var rightColumnTop = navBarWrapper.getBoundingClientRect().height;

      var fixedContainer = document.querySelector('#fixed_container');
      if (fixedContainer) {
        var fixedContainerHeight = fixedContainer.getBoundingClientRect().height;
        var fixedContainerTop = window.scrollY - rightColumnTop;

        if (window.outerWidth > 768) {
          if (
            fixedContainerTop < rightColumnHeight - fixedContainerHeight &&
            fixedContainerTop > 0
          ) {
            fixedContainer.style.top = fixedContainerTop + 'px';
          }
        } else {
          document.querySelector('#fixed_container').style.top = 0;
        }
      }
    }
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      // getAuthor,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    // const author = getAuthor(new UUID('5eafb7eb-755e-4c8e-b033-539fe0a99bfc'))

    const { ...bookingData } = values;
    const bookingDates = {
      startDate: new Date(Date.UTC(2025, 7, 14)),
      endDate: new Date(Date.UTC(2025, 7, 16)),
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPageSteps', routeConfiguration(), {}, {}),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values, alias) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim(), alias)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      // unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      // onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      // reviews,
      // fetchReviewsError,
      // sendEnquiryInProgress,
      // sendEnquiryError,
      // timeSlots,
      // fetchTimeSlotsError,
      // categoriesConfig,
      // amenitiesConfig,
      // languageConfig,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    // console.log('current listing');
    // console.log(currentListing);

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    // const listingType = isDraftVariant
    //   ? LISTING_PAGE_PARAM_TYPE_DRAFT
    //   : LISTING_PAGE_PARAM_TYPE_EDIT;
    // const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    // console.log('Location');
    // console.log(publicData);

    // const richTitle = (
    //   <span>
    //     {richText(title, {
    //       longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
    //       longWordClass: css.longWord,
    //     })}
    //   </span>
    // );

    // const bookingTitle = (
    //   <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    // );
    // const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });
    //
    // const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <PageWrapper title={errorTitle} scrollingDisabled={scrollingDisabled}>
          {/* <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn> */}
        </PageWrapper>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <PageWrapper title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          {/* <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn> */}
        </PageWrapper>
      );
    }

    // const handleViewPhotosClick = e => {
    //   // Stop event from bubbling up to prevent image click handler
    //   // trying to open the carousel as well.
    //   e.stopPropagation();
    //   this.setState({
    //     imageCarouselOpen: true,
    //   });
    // };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    // const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const isPremium =
      currentListing.attributes.metadata.premium && currentListing.attributes.metadata.premium > 0;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    this.authorOfTheListing = ensuredAuthor;

    const listingLocation = currentListing.attributes.publicData.location.address;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    // console.log('ensured user');
    // console.log(ensuredAuthor.attributes.profile.publicData);

    const usersPublicData = ensuredAuthor.attributes.profile.publicData;

    // const { formattedPrice } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    // const listingImages = (listing, variantName) =>
    //   (listing.images || [])
    //     .map(image => {
    //       const variants = image.attributes.variants;
    //       const variant = variants ? variants[variantName] : null;
    //
    //       // deprecated
    //       // for backwards combatility only
    //       const sizes = image.attributes.sizes;
    //       const size = sizes ? sizes.find(i => i.name === variantName) : null;
    //
    //       return variant || size;
    //     })
    //     .filter(variant => variant != null);

    const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];
    // const facebookImages = listingImages(currentListing, 'facebook');
    // const twitterImages = listingImages(currentListing, 'twitter');
    // const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    // const siteTitle = config.siteTitle;
    // const schemaTitle = intl.formatMessage(
    //   { id: 'ListingPage.schemaTitle' },
    //   { title, price: formattedPrice, siteTitle }
    // );

    // console.log('listing images');
    // console.log(currentListingImages);

    // const hostLink = (
    //   <NamedLink
    //     className={css.authorNameLink}
    //     name="ListingPage"
    //     params={params}
    //     to={{ hash: '#host' }}
    //   >
    //     {authorDisplayName}
    //   </NamedLink>
    // );

    // const category =
    //   publicData && publicData.category ? (
    //     <span>
    //       {categoryLabel(categoriesConfig, publicData.category)}
    //       <span className={css.separator}>•</span>
    //     </span>
    //   ) : null;

    // const isNightly = unitType === LINE_ITEM_NIGHT;
    // const isDaily = unitType === LINE_ITEM_DAY;

    // const unitTranslationKey = isNightly
    //   ? 'ListingPage.perNight'
    //   : isDaily
    //   ? 'ListingPage.perDay'
    //   : 'ListingPage.perUnit';

    // const rateCode = () => {
    //   return injectIntl(<FormattedMessage id={unitTranslationKey} />);
    // };

    // const videos = () => {
    //   let videoElements = [];
    //   if (publicData.videos) {
    //     publicData.videos.map(video => {
    //       videoElements.push(<ProfileVideo url={video} />);
    //     });
    //   }
    //   return videoElements;
    // };

    let simpleDialog;

    const bookNow = () => {
      // console.log(currentListing);
      const isHourly =
        currentListing.attributes.publicData &&
        currentListing.attributes.publicData.per === 'hourly';
      if (isHourly) {
        simpleDialog.show();
      } else {
        handleBookingSubmit({ quantity: 1, currentListing });
      }
    };

    const modelStyle = {
      width: 'auto',
      marginTop: '0',
      marginLeft: 0,
      minHeight: '100px',
      top: '50px',
      left: '20px',
      right: '20px',
      height: '90vh',
    };

    const facebook = usersPublicData && usersPublicData.facebook ? usersPublicData.facebook : '';
    const linkedin = usersPublicData && usersPublicData.linkedin ? usersPublicData.linkedin : '';
    const twitter = usersPublicData && usersPublicData.twitter ? usersPublicData.twitter : '';
    const youtube = usersPublicData && usersPublicData.youtube ? usersPublicData.youtube : '';

    const rate_type =
      currentListing && currentListing.attributes.publicData.per === 'hourly' ? 'hour' : 'package';

    const PageTitle = currentListing && currentListing.attributes.title;
    const pageImage =
      currentListingImages && currentListingImages[0].attributes.variants['landscape-crop2x'].url;
    const pageDescription =
      currentListing && currentListing.attributes.description.substring(0, 250);
    const keywords = currentListing.attributes.publicData['type-of-service'];

    const authorId = ensuredAuthor && ensuredAuthor.id.uuid;
    const contactNow = () => {
      if (isAuthenticated) {
        if (authorId !== currentUser.id.uuid) {
          this.props.onContactNow({
            name: authorDisplayName,
            userId: authorId,
            isOpened: true,
            isOnline: false,
            messages: [],
          });
        }
      } else {
        notifyError('Login before contacting')
      }
    };

    return (
      <PageWrapper
        title={`${PageTitle} | Viveka`}
        image={pageImage}
        className={css.page_wrapper}
        description={pageDescription}
        keywords={keywords}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: pageDescription,
          name: PageTitle,
          image: [pageImage],
        }}
        isSocketConnectable={true}
      >
        <PublicPageWrapper page={'page-enterprise'}>
          <SkyLight
            hideOnOverlayClicked
            ref={ref => (simpleDialog = ref)}
            title="Book now"
            className={css.model}
            dialogStyles={modelStyle}
          >
            <BookingModal
              currentListing={currentListing}
              handleBookingSubmit={handleBookingSubmit}
            />
          </SkyLight>
          <div className={css.root}>
            <div className={css.mobile_booking_container}>
              <ListingProfileMobile
                isPremium={isPremium}
                rate={convertMoneyToNumber(price)}
                name={authorDisplayName}
                user={ensuredAuthor}
                onBooknow={bookNow}
                onContactNow={contactNow}
              />
            </div>

            <div className={css.listing_outer}>
              <div className={css.left_container}>
                <div className={css.left_container_inner}>
                  <PremiumProfileBio
                    services={currentListing.attributes.publicData['type-of-service']}
                    title={title}
                    is_best={true}
                    bio={description}
                    images={currentListingImages}
                    videos={publicData.videos}
                  />

                  {/*{videos()}*/}

                  {/*<h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.recent_event_gallery" />
                  </h2>*/}
                  {/*{gallery()}*/}

                  {/*<h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.frequently_booked" />
                  </h2>
                  <div className={css.other_profiles}>
                    <div className={css.other_profile_item}>
                      <ProfileCard price={1000} rating={3} title={"User 1"} desc={"hello world"} imgUrl={"https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"}/>
                    </div>
                    <div className={css.other_profile_item}>
                      <ProfileCard className={css.other_profile_item} price={1000} rating={4} title={"User 2"} desc={"hello world"} imgUrl={"https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"}/>
                    </div>
                  </div>*/}

                  <h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.language" />
                  </h2>
                  <ProfileLanguages languages={currentListing.attributes.publicData.language} />

                  <h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.meeting_type" />
                  </h2>
                  <ProfileMeetingTypes
                    meetings={currentListing.attributes.publicData['meeting-type']}
                  />

                  <h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.service_delivery_to" />
                  </h2>
                  <ServiceDeliveryTo
                    deliverTypes={currentListing.attributes.publicData['service-delivery-to']}
                  />

                  {/*<h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.type_of_service" />
                  </h2>
                  <ProfileServiceTypes
                    services={currentListing.attributes.publicData['type-of-service']}
                  />*/}

                  {/* <h2 className={css.section_title}>
                    <FormattedMessage id="ListingPage.locationTitle" />
                  </h2>*/}

                  <div className={css.section_map}>
                    <SectionMapMaybe
                      zoom={5}
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing.id}
                    />
                  </div>
                </div>
              </div>
              <div id="right_column" className={css.right_container}>
                <div id="fixed_container" className={css.right_container_inner}>
                  <ListingProfileRight
                    title={title}
                    name={authorDisplayName}
                    keywords={currentListing.attributes.publicData.keywords}
                    isPremium={isPremium}
                    user={ensuredAuthor}
                    rate={convertMoneyToNumber(price)}
                    rating={3}
                    reviewCount={24}
                    location={listingLocation}
                    facebook={facebook}
                    linkedin={linkedin}
                    twitter={twitter}
                    youtube={youtube}
                    handleBookingSubmit={handleBookingSubmit}
                    currentListing={currentListing}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    rate_type={rate_type}
                    isOnline={this.state.userOnline}
                    onContactNow={contactNow}
                    history={this.props.history}
                  />
                </div>
              </div>
            </div>
          </div>
        </PublicPageWrapper>
      </PageWrapper>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  categoriesConfig: config.custom.categories,
  amenitiesConfig: config.custom.amenities,
  languageConfig: config.custom.languages,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  categoriesConfig: array,
  amenitiesConfig: array,
  languageConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const { socket } = state.socket;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getAuthor = id => {
    const ref = { id, type: 'user' };
    const author = getMarketplaceEntities(state, [ref]);
    return author.length === 1 ? author[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getAuthor,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    socket,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onSendEnquiry: (listingId, message, alias) => dispatch(sendEnquiry(listingId, message, alias)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),

  onContactNow: data => dispatch(addChat(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
